// @ts-nocheck
// import window from 'global/window';
import numUtil from '../utils/numUtil';

export default class BaseVideoHandler {
  vElement_;
  intervalIds_;
  preDecodeFrames_;
  checkTime_;

  fpsArr_;
  fpsArrLenLimit_;
  // 计算gop使用
  preIframePts;
  currentFramePts;

  constructor(velement) {
    this.vElement_ = velement;
    // this.initData();
    this.intervalIds_ = {};
    this.fpsArr_ = [];
    this.fpsArrLenLimit_ = 10;
    this.preDecodeFrames_ = 0;
    this.checkTime_ = 0;
    this.preIframePts = -1;
    this.currentFramePts = -1;
  }

  destroy() {
    this.vElement_ = null;
    this._clearIntervalIds();
    this.intervalIds_ = null;
    this.fpsArr_ = null;
  }

  initData() {
    this.intervalIds_ = {};
    this.fpsArr_ = [];
    this.fpsArrLenLimit_ = 10;
    this.preDecodeFrames = 0;
    this.checkTime_ = 0;
  }

  startCheckVideoElementState(name, callback, time) {
    this.checkTime_ = time;
    this.startInterval(name, () => this.checkVElementState(callback), time);
  }

  stopCheckVideoElementState(name) {
    this.stopInterval(name);
  }

  // 开始interval
  startInterval(name, intervalFunc, time) {
    if (intervalFunc) {
      this.intervalIds_[name] = setInterval(intervalFunc, time);
    }
  }

  // 停止interval
  stopInterval(name) {
    this.intervalIds_[name] && clearInterval(this.intervalIds_[name]);
    this.intervalIds_[name] = null;
  }

  _clearIntervalIds() {
    Object.keys(this.intervalIds_).forEach(function (val, key) {
      val && clearInterval(val);
    });
  }

  // 调用回调函数
  callCallback(...args) {
    if (args.length >= 1) {
      let name = args[0];
      let cb = this.callbackMap_ != null ? this.callbackMap_[name] : null;
      if (cb) {
        switch (args.length) {
          case 1: {
            cb();
            break;
          }
          case 2: {
            cb(args[1]);
            break;
          }
          case 3: {
            cb(args[1], args[2]);
            break;
          }
          case 4: {
            cb(args[1], args[2], args[3]);
          }
          default: {
            console.log('too much arg');
          }
        }
      }
    }
  }

  // 读取video标签中的状态
  checkVElementState(callback) {
    let video = this.vElement_;
    let droppedFrames = 0;
    let corruptedFrames = 0;
    let decodedFrames = 0;

    if (video.currentTime <= 0) {
      return;
    }
    const videoPlaybackQuality = video.getVideoPlaybackQuality;
    if (videoPlaybackQuality && typeof videoPlaybackQuality === typeof Function) {
      droppedFrames = video.getVideoPlaybackQuality().droppedVideoFrames;
      corruptedFrames = video.getVideoPlaybackQuality().corruptedVideoFrames;
      decodedFrames = video.getVideoPlaybackQuality().totalVideoFrames;
    } else if (video.webkitDroppedFrameCount) {
      droppedFrames = video.webkitDroppedFrameCount;
      decodedFrames = video.webkitDecodedFrameCount;
    }

    let avcFps = decodedFrames / video.currentTime;
    let currentFps = (decodedFrames - this.preDecodeFrames_) / (this.checkTime_ / 1000.0);
    this.preDecodeFrames_ = decodedFrames;
    if (decodedFrames > 0) {
      this.fpsArr_.push(currentFps);
      this._fixArr(this.fpsArr_, this.fpsArrLenLimit_);
    }

    // 默认10个currentFps的平均值
    let avc2Fps = this.fpsArr_.length === 0 ? 0 : this._sumArr(this.fpsArr_) / this.fpsArr_.length;

    // console.log('played:',that._timeRangesToString(video.played));
    // console.log('buffered:',that._timeRangesToString(video.buffered));
    // console.log('seekable:',that._timeRangesToString(video.seekable));

    let state = {
      duration: video.duration,
      Buffered: this._timeRangesToString(video.buffered),
      Seekable: this._timeRangesToString(video.seekable),
      // played: that.timeRangesToString(video.played),
      played: '0 ~ ' + video.currentTime.toFixed(2),
      audioBuffer: '0 ~ 0',
      videoBuffer: '0 ~ 0',
      droppedFrames: droppedFrames,
      corruptedFrames: corruptedFrames,
      Bandwidth: 0,
      decodedFrames: decodedFrames,
      avcFps: numUtil.fixNum2(avcFps),
      curFps: numUtil.fixNum2(currentFps),
      avc2Fps: numUtil.fixNum2(avc2Fps),
    };
    if (callback != null) {
      callback(state);
    }
  }

  _timeRangesToString(r) {
    let str = '';
    for (let i = 0; i < r.length; i++) {
      str = str + r.start(i).toFixed(2) + ' ~ ' + r.end(i).toFixed(2) + ' ';
    }
    return str;
  }

  _fixArr(arr, limit) {
    for (let i = limit; i < arr.length; i++) {
      arr.shift();
    }
  }

  _sumArr(arr) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }
}
