import HlsHandler from './hlshandler';
import FlvHandler from './flvhandler';
import DashHandler from './dashhandler';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HlsHandler,
  FlvHandler,
  DashHandler,
  // CallbackName: CallbackFuncs
};
