import React, { useLayoutEffect } from 'react';
import {
  message,
  Input,
  Button,
  Status,
  Select,
  Card,
  Row,
  Col,
  Form,
  Text,
  Modal,
  Icon,
  Tabs,
  TabPanel,
  List,
} from '@tencent/tea-component';
import banner from './assets/banner@2x.png';
import logo from './assets/logo192.png';
import '@tencent/tea-component/dist/tea.css';
import './App.css';
import { useState } from 'react';
import avhandler from './avhandle';
import { useRef } from 'react';
import { BasicLine } from '@tencent/tea-chart/lib/basicline';
import numUtil from './utils/numUtil';
import moment from 'moment';
import parser from 'ua-parser-js';
import TXLivePlayer from './static/webrtc';
import { useMemo } from 'react';
import { useEffect } from 'react';
type Proto = 'hls' | 'dash' | 'flv' | 'webrtc';

const protocol = window.location.protocol;
const ua = navigator.userAgent;
const info = parser(ua);
const appInfo = {
  useragent: ua,
  browserVersion: `${info.browser.name} ${info.browser.version}`,
  os: `${info.os.name} ${info.os.version}`,
  platform: info.device.type === 'mobile' ? 'mobile' : 'desktop',
};

const protoOption = [
  {
    value: 'flv',
    text: 'flv',
  },
  {
    value: 'hls',
    text: 'hls',
  },
  {
    value: 'dash',
    text: 'dash',
  },
  {
    value: 'webrtc',
    text: 'webrtc',
  },
];

const initMediaData = {
  // 编码信息
  codec: {
    video: '--',
    audio: '--',
  },
  // 分辨率信息
  videoWidthHeight: '--',
  // 视频header 源数据
  metaDatas: [] as any[],
  // 对于flv来说是第一个I帧的时间， dash是第一个ts的时间
  IframeTime: 0,
};

// 实时数据
const initRealData = {
  decodedFrames: 0,
  droppedFrames: 0,
  // 实时视频帧率
  fps: 0,
  // 音视频帧率
  audioPackages: 0,
  afps: 0,
  vfps: 0,
  fpsArr: [] as any[],
  // key 为当前时间 val为 gop
  currentGop: '0',
  gopList: [] as any[],
  // 码率信息
  audioRate: '0',
  videoRate: '0',
  rateArr: [] as any[],
  // 下载速度
  speed: '0',
};

function prettifyNum(num: number) {
  let msg = '';
  if (num > 1024 * 1024 * 1024) {
    msg = numUtil.fixNum2(num / 1024.0 / 1024.0 / 1024.0) + ' G';
  } else if (num > 1024 * 1024) {
    msg = numUtil.fixNum2(num / 1024.0 / 1024.0) + ' M';
  } else if (num > 1024) {
    msg = numUtil.fixNum2(num / 1024.0) + ' K';
  } else {
    msg = num + '';
  }
  return msg;
}

function stackPush(arr: any[], item: any[], length: number) {
  let list = [...arr];
  if (arr.length >= length * item.length) {
    list = arr.slice(item.length);
  }
  list.push(...item);
  return list;
}

function stackFront(arr: any[], item: any[], length: number) {
  let list = [...arr];
  if (arr.length >= length * item.length) {
    list = arr.slice(0, arr.length - item.length);
  }
  list.unshift(...item);
  return list;
}

function formatCodec(codec = '') {
  if (codec.includes('avc')) return 'H.264';
  if (codec.includes('hevc')) return 'H.265';
  if (codec.includes('mp4a')) return 'AAC';
  return codec;
}

function App() {
  const maxLength = 300;
  const [url, setUrl] = useState('');
  const [isStart, setIsStart] = useState(false);
  const [prototype, setPrototype] = useState<Proto>('flv');
  const videoHandler = useRef<any>(null);
  const [realData, setRealData] = useState({ ...initRealData });
  const [mediaData, setMediaData] = useState({ ...initMediaData });
  const [offer, setOffer] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [m3u8List, setM3u8List] = useState<any[]>([]);
  const [tsList, setTsList] = useState<any[]>([]);
  const [m4sList, setM4sList] = useState<any[]>([]);
  const [mpdList, setMpdList] = useState<any[]>([]);
  const [handler, setHandler] = useState(0);

  function resetBtn() {
    setMediaData({ ...initMediaData });
    setRealData({ ...initRealData });
    setShowMore(false);
    setOffer([]);
    setAnswer([]);
    setM3u8List([]);
    setTsList([]);
    setIsStart(false);
  }

  function resetMediaHandler() {
    resetBtn();
    videoHandler.current?.reset?.();
    videoHandler.current?.destroy?.();
  }

  function startPlay() {
    setIsStart(true);
  }
  // video state回调
  function checkState(stats: any) {
    setRealData((realData) => ({ ...realData, ...stats, fps: stats.avc2Fps }));
  }
  // flv error 回调函数
  function flvErrorCB(res: any, _: any, info: any) {
    resetMediaHandler();
    let msg;
    if (info.code !== -1) {
      msg = `${res}: ${info.code} ${info.msg}`;
    } else {
      msg = `${res}: ${info.msg}`;
    }
    message.error({ content: msg });
  }
  // statistics info信息回调
  function flvStatisticsInfoCB(res: any) {
    setRealData((realData) => ({ ...realData, speed: prettifyNum(res.speed * 8 * 1024) + 'b/s' }));
  }
  // flv加载完回调函数
  function flvLoadCompleteCB() {
    resetMediaHandler();
    message.success({ content: 'flv loaded' });
  }
  // media info回调函数
  function flvMediaInfoCB(res: any) {
    console.log('meida_info', res);
    mediaData.codec = {
      audio: res.audioCodec || mediaData.codec.audio,
      video: res.videoCodec || mediaData.codec.video,
    };
    setMediaData({ ...mediaData });
  }
  function flvMetaDataArrivedCB(res: any) {
    const newMeta = {
      ...res,
      ...res.metadata,
    };
    delete newMeta.metadata;

    const meta = {
      title: mediaData.metaDatas.length + 1 + '. Meta Data',
      value: Object.keys(newMeta).map((key) => ({
        label: key,
        value: newMeta[key],
      })),
    };

    console.log('metadata', res, mediaData.metaDatas);
    mediaData.metaDatas = stackPush(mediaData.metaDatas, [meta], maxLength);
    mediaData.videoWidthHeight = res.width + ' x ' + res.height;
    setMediaData({ ...mediaData });
  }
  function handleGOP(gop: string) {
    setRealData((realData) => {
      realData.currentGop = gop;
      realData.gopList = stackPush(
        realData.gopList,
        [
          {
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
            value: +gop,
          },
        ],
        maxLength,
      );
      return realData;
    });
    // drawLineChart('gopChart', 'GOP(s)', gopList, gopRefTimeList);
  }

  function flvRateInfoCB(stats: any) {
    if (!(+stats.audioRate && +stats.videoRate)) return;
    setRealData((realData) => {
      realData.audioRate = prettifyNum(stats.audioRate) + 'b/s';
      realData.videoRate = prettifyNum(stats.videoRate) + 'b/s';
      realData.rateArr = stackPush(
        realData.rateArr,
        [
          {
            instance: '音频码率',
            value: +(stats.audioRate / 1024).toFixed(2),
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            instance: '视频码率',
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
            value: +(stats.videoRate / 1024).toFixed(2),
          },
        ],
        maxLength,
      );
      realData.speed = prettifyNum(stats.audioRate + stats.videoRate) + 'b/s';
      return realData;
    });
  }
  function firstIframetimeCB(data: any) {
    mediaData.IframeTime = data.time;
    setMediaData({ ...mediaData });
  }
  function fpsInfoCB(info: any) {
    setRealData((realData) => {
      realData.afps = info.audio.toFixed(2);
      realData.vfps = info.video.toFixed(2);
      realData.fpsArr = stackPush(
        realData.fpsArr,
        [
          {
            instance: '音频帧率',
            value: +realData.afps,
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            instance: '视频帧率',
            value: +realData.vfps,
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
        ],
        maxLength,
      );
      return realData;
    });
    // setTimeout(drawFpsChart, 1);
  }

  function dashErrorCB(type: any, content: any) {
    resetMediaHandler();
    const msg = `${type}: ${content}`;
    message.error({ content: msg });
  }

  function dashMediaInfoCB(data: any) {
    const metaData = data.config;
    const meta = {
      title: mediaData.metaDatas.length + '. Meta Data',
      value: Object.keys(metaData).map((key) => ({
        label: key,
        value: metaData[key],
      })),
    };
    mediaData.metaDatas = stackPush(mediaData.metaDatas, [meta], maxLength);
    if (data.type === 'video') {
      mediaData.videoWidthHeight = data.config[0].width + ' x ' + data.config[0].height;
    }
    setMediaData({ ...mediaData });
  }

  function dashFragBufferedCB(data: {
    fragUrl: any;
    mediaType: any;
    loadTime: number;
    duration: number;
    quality: any;
  }) {
    let msg = `M4S: url=${data.fragUrl}<br> ${data.mediaType} load time ${data.loadTime.toFixed(
      2,
    )} ms, duration ${data.duration.toFixed(2)} s, quality ${data.quality} <br><br>`;
    setM4sList((list) => stackFront(list, [msg], maxLength));
  }

  function dashMPDLoadedCB(data: { url: any; loadTime: number }) {
    let msg = `MPD: url=${data.url}<br>load time ${data.loadTime.toFixed(2)} ms <br><br>`;
    setMpdList((list) => stackFront(list, [msg], maxLength));
  }

  function loadFlvPlayer() {
    const config = {
      debug: true,
      type: 'flv',
      isLive: true,
      url: url,
    };
    const videoElement = document.getElementById('remote_video');

    const cbMap = {
      check_element_state_cb: checkState,
      error_cb: flvErrorCB,
      statistics_info_cb: flvStatisticsInfoCB,
      loading_complete_cb: flvLoadCompleteCB,
      media_info_cb: flvMediaInfoCB,
      gop_arrived: handleGOP,
      flv_rate_info_cb: flvRateInfoCB,
      metadata_arrived_cb: flvMetaDataArrivedCB,
      first_Iframe_time_cb: firstIframetimeCB,
      fps_info_cb: fpsInfoCB,
    };

    videoHandler.current = new avhandler.FlvHandler(config.url, config, videoElement, cbMap);
    // 加载player
    videoHandler.current.loadFlvPlayer();
    videoHandler.current.play();
  }

  // 加载并播放hls流
  function loadHlsPlayer() {
    const config = {
      debug: true,
      enableWorker: true,
      lowLatencyMode: true,
    };
    const videoElement = document.getElementById('remote_video');
    const cbMap = {
      check_element_state_cb: checkState,
      error_cb: hlsErrorCB,
      gop_arrived: handleGOP,
      hls_metadata_cb: hlsMetaDataCB,
      frag_buffered_cb: hlsFragBufferedCB,
      hls_level_loaded_cb: hlsLevelLoadCB,
      hls_rate_info_cb: flvRateInfoCB,
      first_TS_time_cb: firstIframetimeCB,
      fps_info_cb: fpsInfoCB,
    };
    videoHandler.current = new avhandler.HlsHandler(url, config, videoElement, cbMap);
    videoHandler.current.loadHlsPlayer().then(function () {
      videoHandler.current.addHlsVideoListener();
    });
  }

  function hlsMetaDataCB(metadata: any) {
    mediaData.codec.audio = metadata.audioCodec;
    mediaData.codec.video = metadata.videoCodec;
    const meta = {
      title: mediaData.metaDatas.length + 1 + '. Meta Data',
      value: Object.keys(metadata).map((key) => ({
        label: key,
        value: metadata[key],
      })),
    };
    mediaData.metaDatas = stackPush(mediaData.metaDatas, [meta], maxLength);
    mediaData.videoWidthHeight = metadata.width + ' x ' + metadata.height;
    console.log(mediaData);

    setMediaData({ ...mediaData });
  }

  function hlsFragBufferedCB(data: {
    fragUrl: any;
    loadTime: number;
    duration: number;
    level: any;
  }) {
    let msg = `TS: url=${data.fragUrl}<br>load time ${data.loadTime.toFixed(
      2,
    )} ms, duration ${data.duration.toFixed(2)} s, level ${data.level} <br><br>`;
    setTsList((tsList) => stackFront(tsList, [msg], maxLength));
  }
  function hlsLevelLoadCB(data: { url: any; loadTime: number }) {
    let msg = `M3U8: url=${data.url}<br>load time ${data.loadTime.toFixed(2)} ms <br><br>`;
    setM3u8List((m3u8List) => stackFront(m3u8List, [msg], maxLength));
  }
  // hls出现错误的回调函数
  function hlsErrorCB(type: any, fatal: any, info: { code: number; text: any }) {
    resetMediaHandler();
    let msg;
    if (info && info.code && info.code >= 0) {
      msg = `${type}: ${info.code} ${info.text}`;
    } else {
      msg = `${type}: ${info.text}`;
    }
    if (fatal) {
      message.error({ content: msg });
    } else {
      message.warning({ content: msg });
    }
  }

  function loadDashPlayer() {
    const videoElement = document.getElementById('remote_video');

    const config = {
      debug: {
        logLevel: 0,
      },
    };

    const cbMap = {
      check_element_state_cb: checkState,
      error_cb: dashErrorCB,
      dash_rate_info_cb: flvRateInfoCB,
      dash_media_info_cb: dashMediaInfoCB,
      first_M4S_time_cb: firstIframetimeCB,
      frag_buffered_cb: dashFragBufferedCB,
      dash_mpd_loaded_cb: dashMPDLoadedCB,
      codec_parsed_cb: flvMediaInfoCB,
      fps_info_cb: fpsInfoCB,
      gop_arrived: handleGOP,
    };

    videoHandler.current = new avhandler.DashHandler(url, config, videoElement, cbMap);
    videoHandler.current.loadDashPlayer();
    videoHandler.current.play();
  }

  function loadWebrtcPlayer() {
    TXLivePlayer.checkSupport((data: any) => {
      if (!data.support || !data.h264Support) {
        message.error({ content: '当前浏览器不支持该协议' });
      } else {
        const videoElement = document.getElementById('remote_video');
        videoHandler.current = new TXLivePlayer();
        videoHandler.current.setPlayerView(videoElement);
        videoHandler.current.startPlay(url);
        videoHandler.current.setPlayListener({
          onPlayEvent: (id: any, data: any) => {
            console.log(id, data);
            if (id < 0) {
              message.error({ content: data.message });
              resetMediaHandler();
            }
            if (id === 1007) {
              setOffer(data?.localSdp?.sdp?.split('\r\n'));
            }
            if (id === 1008) {
              setAnswer(data.remoteSdp.sdp?.split('\r\n'));
            }
          },
          onPlayStats: ({ video = {} as any, audio = {} as any }) => {
            setMediaData((mediaData) => ({
              ...mediaData,
              codec: {
                video: video.codec,
                audio: audio.codec,
              },
              videoWidthHeight: `${video.frameWidth} * ${video.frameHeight}`,
            }));
            if (!mediaData.videoWidthHeight && !video.framesDecoded) return;
            setRealData((realData) => ({
              decodedFrames: video.framesDecoded,
              droppedFrames: video.framesDropped,
              speed: prettifyNum(audio.bitrate + video.bitrate) + 'b/s',
              fps: video.framesPerSecond,
              audioPackages: audio.packetsReceived,
              afps: audio.packetsReceived - realData.audioPackages,
              vfps: video.framesPerSecond,
              fpsArr: stackPush(
                realData.fpsArr,
                [
                  {
                    instance: '音频帧率',
                    value: audio.packetsReceived - realData.audioPackages,
                    time: moment().format('YYYY-MM-DD HH:mm:ss'),
                  },
                  {
                    instance: '视频帧率',
                    value: video.framesPerSecond,
                    time: moment().format('YYYY-MM-DD HH:mm:ss'),
                  },
                ],
                maxLength,
              ),
              currentGop: '0',
              gopList: [] as any[],
              // 码率信息
              audioRate: prettifyNum(audio.bitrate) + 'b/s',
              videoRate: prettifyNum(video.bitrate) + 'b/s',
              rateArr: stackPush(
                realData.rateArr,
                [
                  {
                    instance: '音频码率',
                    value: +(audio.bitrate / 1024).toFixed(2),
                    time: moment().format('YYYY-MM-DD HH:mm:ss'),
                  },
                  {
                    instance: '视频码率',
                    time: moment().format('YYYY-MM-DD HH:mm:ss'),
                    value: +(video.bitrate / 1024).toFixed(2),
                  },
                ],
                maxLength,
              ),
            }));
          },
        });
      }
    });
  }

  useLayoutEffect(() => {
    if (!isStart) return;
    switch (prototype) {
      case 'flv':
        loadFlvPlayer();
        break;
      case 'hls':
        loadHlsPlayer();
        break;
      case 'dash':
        loadDashPlayer();
        break;
      case 'webrtc':
        loadWebrtcPlayer();
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStart, prototype]);

  useEffect(() => {
    const interval = setInterval(() => {
      setHandler((id) => id + 1);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const realView = useMemo(
    () => (
      <div className="App-main">
        <div className="App-main-box">
          {!isStart ? (
            <Status icon="blank" description="请先输入播放地址并点击开始监控" />
          ) : (
            <div className="App-main-content">
              <Card bordered style={{ display: 'flex', flexWrap: 'wrap' }}>
                <video
                  style={{ backgroundColor: '#000' }}
                  id="remote_video"
                  height="440px"
                  width="645px"
                  controls
                  autoPlay
                  playsInline
                />
                <Card.Body style={{ position: 'relative', flex: '1' }}>
                  <Row>
                    <Col span={12}>
                      <Form.Title>系统</Form.Title>
                      <Form readonly>
                        <Form.Item label="浏览器">
                          <Form.Text>{appInfo.browserVersion}</Form.Text>
                        </Form.Item>
                        <Form.Item label="操作系统">
                          <Form.Text>{appInfo.os}</Form.Text>
                        </Form.Item>
                        <Form.Item label="平台">
                          <Form.Text>{appInfo.platform}</Form.Text>
                        </Form.Item>
                        <Form.Item label="UserAgent">
                          <Text reset tooltip={appInfo.useragent} className="max-line-2">
                            {appInfo.useragent}
                          </Text>
                        </Form.Item>
                      </Form>

                      <Form.Title style={{ marginTop: '20px' }}>媒体信息</Form.Title>
                      <Form readonly>
                        <Form.Item label="视频编码">
                          <Form.Text className="max-line-2">
                            <Text reset tooltip={mediaData.codec.video} className="max-line-2">
                              {formatCodec(mediaData.codec.video)}
                            </Text>
                          </Form.Text>
                        </Form.Item>
                        <Form.Item label="音频编码">
                          <Form.Text className="max-line-2">
                            <Text reset tooltip={mediaData.codec.audio} className="max-line-2">
                              {formatCodec(mediaData.codec.audio)}
                            </Text>
                          </Form.Text>
                        </Form.Item>
                        <Form.Item label="分辨率">
                          <Form.Text>{mediaData.videoWidthHeight}</Form.Text>
                        </Form.Item>
                        <Form.Item label="首个I帧耗时">
                          <Form.Text>{mediaData.IframeTime} ms</Form.Text>
                        </Form.Item>
                        {prototype === 'flv' && (
                          <Form.Item label="METADATA">
                            <Form.Text>
                              {mediaData.metaDatas.map((item) => (
                                <p>
                                  <Text>{item.title}</Text>
                                  <Button
                                    type="link"
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                      const model = Modal.show({
                                        caption: item.title,
                                        onClose: () => model.destroy(),
                                        children: (
                                          <>
                                            <Modal.Body>
                                              <Form layout="inline">
                                                {item.value.map(
                                                  (meta: { label: string; value: string }) => (
                                                    <Form.Item
                                                      label={
                                                        <div style={{ width: '130px' }}>
                                                          {meta.label}
                                                        </div>
                                                      }
                                                    >
                                                      <Form.Text style={{ width: '80px' }}>
                                                        {meta.value}
                                                      </Form.Text>
                                                    </Form.Item>
                                                  ),
                                                )}
                                              </Form>
                                            </Modal.Body>
                                          </>
                                        ),
                                      });
                                    }}
                                  >
                                    查看
                                  </Button>
                                </p>
                              ))}
                            </Form.Text>
                          </Form.Item>
                        )}
                      </Form>
                    </Col>

                    <Col span={12}>
                      <Form.Title>实时统计信息</Form.Title>
                      <Form readonly>
                        <Form.Item label="解码帧数">
                          <Form.Text>{realData.decodedFrames}</Form.Text>
                        </Form.Item>
                        <Form.Item label="丢失帧数">
                          <Form.Text>{realData.droppedFrames}</Form.Text>
                        </Form.Item>
                        <Form.Item label="下载速率">
                          <Form.Text>{realData.speed}</Form.Text>
                        </Form.Item>
                        <Form.Item label="实时帧率">
                          <Form.Text>{realData.fps || '-'} fps</Form.Text>
                        </Form.Item>
                        <Form.Item label="音频帧率">
                          <Form.Text>{realData.afps || '-'} fps</Form.Text>
                        </Form.Item>
                        <Form.Item label="视频帧率">
                          <Form.Text>{realData.vfps || '-'} fps</Form.Text>
                        </Form.Item>
                        <Form.Item label="GOP(S)">
                          <Form.Text>{realData.currentGop}</Form.Text>
                        </Form.Item>
                        <Form.Item label="视频码率">
                          <Form.Text>{realData.videoRate}</Form.Text>
                        </Form.Item>
                        <Form.Item label="音频码率">
                          <Form.Text>{realData.audioRate}</Form.Text>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                  {!showMore && prototype !== 'flv' && (
                    <div
                      onClick={() => setShowMore(true)}
                      style={{
                        width: '100%',
                        position: 'absolute',
                        left: 0,
                        bottom: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon type="arrowdown" />
                      <Button type="link">查看更多</Button>
                    </div>
                  )}
                </Card.Body>
                {showMore && (
                  <Card.Body style={{ width: '100%', height: 440, position: 'relative' }}>
                    {prototype === 'webrtc' && (
                      <Tabs
                        tabs={[
                          { label: 'Offer', id: 'Offer' },
                          { label: 'Answer', id: 'Answer' },
                        ]}
                      >
                        <TabPanel id="Offer">
                          <List style={{ height: '350px', overflowY: 'auto' }}>
                            {offer.map((item, i) => (
                              <List.Item key={i}>{item}</List.Item>
                            ))}
                          </List>
                        </TabPanel>
                        <TabPanel id="Answer">
                          <List style={{ height: '350px', overflowY: 'auto' }}>
                            {answer.map((item, i) => (
                              <List.Item key={i}>{item}</List.Item>
                            ))}
                          </List>
                        </TabPanel>
                      </Tabs>
                    )}
                    {prototype === 'hls' && (
                      <Tabs
                        tabs={[
                          { label: 'M3U8', id: 'M3U8' },
                          { label: 'HLS TS', id: 'TS' },
                        ]}
                      >
                        <TabPanel id="M3U8">
                          <List style={{ height: '350px', overflowY: 'auto' }}>
                            {m3u8List.map((item, i) => (
                              <List.Item key={i}>
                                <span dangerouslySetInnerHTML={{ __html: item }}></span>
                              </List.Item>
                            ))}
                          </List>
                        </TabPanel>
                        <TabPanel id="TS">
                          <List style={{ height: '350px', overflowY: 'auto' }}>
                            {tsList.map((item, i) => (
                              <List.Item key={i}>
                                <span dangerouslySetInnerHTML={{ __html: item }}></span>
                              </List.Item>
                            ))}
                          </List>
                        </TabPanel>
                      </Tabs>
                    )}
                    {prototype === 'dash' && (
                      <Tabs
                        tabs={[
                          { label: 'MPD', id: 'MPD' },
                          { label: 'M4S', id: 'M4S' },
                        ]}
                      >
                        <TabPanel id="MPD">
                          <List style={{ height: '350px', overflowY: 'auto' }}>
                            {mpdList.map((item, i) => (
                              <List.Item key={i}>
                                <span dangerouslySetInnerHTML={{ __html: item }}></span>
                              </List.Item>
                            ))}
                          </List>
                        </TabPanel>
                        <TabPanel id="M4S">
                          <List style={{ height: '350px', overflowY: 'auto' }}>
                            {m4sList.map((item, i) => (
                              <List.Item key={i}>
                                <span dangerouslySetInnerHTML={{ __html: item }}></span>
                              </List.Item>
                            ))}
                          </List>
                        </TabPanel>
                      </Tabs>
                    )}
                    {showMore && (
                      <div
                        onClick={() => setShowMore(false)}
                        style={{
                          width: '100%',
                          position: 'absolute',
                          left: 0,
                          bottom: 5,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon type="arrowup" />
                        <Button type="link">收起</Button>
                      </div>
                    )}
                  </Card.Body>
                )}
              </Card>

              {prototype !== 'webrtc' && (
                <Card bordered>
                  <Card.Body
                    title={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        GOP<Text theme="weak">{`{s}`}</Text>
                      </div>
                    }
                  >
                    <BasicLine
                      height={440}
                      interaction={['zoom']}
                      position="time*value"
                      tooltip={{
                        enable: true,
                        // formatter: (series) => genTooltip(series, 'Mbps'),
                      }}
                      dataSource={realData.gopList}
                    />
                  </Card.Body>
                </Card>
              )}
              <Card bordered>
                <Card.Body
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      码率<Text theme="weak">{`{kb/s}`}</Text>
                    </div>
                  }
                >
                  <BasicLine
                    height={440}
                    interaction={['zoom']}
                    position="time*value"
                    tooltip={{
                      enable: true,
                      // formatter: (series) => genTooltip(series, 'Mbps'),
                    }}
                    dataSource={realData.rateArr}
                    color="instance"
                  />
                </Card.Body>
              </Card>
              <Card bordered>
                <Card.Body
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      帧率<Text theme="weak">{`{fps}`}</Text>
                    </div>
                  }
                >
                  <BasicLine
                    height={440}
                    interaction={['zoom']}
                    position="time*value"
                    tooltip={{
                      enable: true,
                      // formatter: (series) => genTooltip(series, 'Mbps'),
                    }}
                    dataSource={realData.fpsArr}
                    color="instance"
                  />
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isStart, showMore, handler],
  );

  return (
    <div className="App">
      <header className="App-header">
        <img src={banner} className="App-banner" alt="banner" />
        <div className="App-header-title">
          <img src={logo} alt="" style={{ width: 33 }} />
          <span style={{ marginLeft: '8px' }}>腾讯云直播 CSS</span>
        </div>
        <div className="App-header-content">
          <div className="header-content-main">
            <span className="header-content-text1">播放监控工具</span>
            <span className="header-content-text2">播放端问题诊断</span>
            <div className="header-content-action">
              <div className="header-content-action-box">
                <Input
                  className="header-content-input radius"
                  value={url}
                  onChange={(v) => {
                    setUrl(v);
                    if (v.includes('flv')) {
                      setPrototype('flv');
                    }
                    if (v.includes('m3u8')) {
                      setPrototype('hls');
                    }
                    if (v.includes('mpd')) {
                      setPrototype('dash');
                    }
                    if (v.includes('webrtc')) {
                      setPrototype('webrtc');
                    }
                  }}
                  placeholder="请输入播放地址"
                />
                <div className="input-action">
                  <div className="input-split"></div>
                  <Select
                    style={{ width: '60px' }}
                    value={prototype}
                    disabled={isStart}
                    onChange={(v: Proto) => {
                      const proto = {
                        hls: 'm3u8',
                        dash: 'mpd',
                        flv: 'flv',
                        webrtc: 'webrtc',
                      };
                      setPrototype(v);
                      if (!url) return;
                      if (v === 'webrtc' && prototype !== 'webrtc') {
                        setUrl(url.replace(`.${proto[prototype]}`, '').replace(protocol, proto[v]));
                      } else if (v !== 'webrtc' && prototype === 'webrtc') {
                        const [a, b] = url.split('?');
                        setUrl(`${a.replace(prototype, protocol)}.${proto[v]}${b ? '?' + b : ''}`);
                      } else {
                        setUrl(url.replace(proto[prototype], proto[v]));
                      }
                    }}
                    options={protoOption}
                  ></Select>
                </div>
              </div>
              {!isStart ? (
                <Button
                  type="primary"
                  className="header-content-start radius"
                  disabled={!url}
                  onClick={startPlay}
                >
                  开始监控
                </Button>
              ) : (
                <Button
                  type="error"
                  className="header-content-stop radius"
                  onClick={() => {
                    resetMediaHandler();
                  }}
                >
                  停止
                </Button>
              )}
              {/* <span className="header-content-or">或</span>
              <Button className="header-content-check radius" disabled={!url}>
                自助诊断
              </Button> */}
            </div>
          </div>
        </div>
      </header>

      {realView}
    </div>
  );
}

export default App;
