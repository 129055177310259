// @ts-nocheck
import BaseVideoHandler from './basehandler';
import * as flvjs from '../static/flv';
console.log(flvjs);

export default class FlvHandler extends BaseVideoHandler {
  flvInfo_;
  config_;
  playUri_;
  callbackMap_;
  cdnBuffer_;
  unWatchFunc_;

  stats_;

  rateHelper_;

  parsedDataLength_;
  parsedData_;
  isAdd_;

  firstIframeHelper_;
  fpsHelper_;
  rateHelper_;

  constructor(playUri, flv_config, velement, callbackMap) {
    super(velement);
    this.config_ = flv_config;
    this.playUri_ = playUri;
    this.callbackMap_ = callbackMap;
    this.firstIframeHelper_ = {
      connectTime: 0,
      isFirstIframe: true,
    };
    this.flvInfo_ = {
      remotePlayer: null,
    };
    this.unWatchFunc_ = {};
    this.parsedDataLength_ = 0;
    this.parsedData_ = [];
    this.isAdd_ = true;
    this.stats_ = {
      audioRate: 0,
      videoRate: 0,
    };
    this.fpsHelper_ = {
      fpsVal_: {
        audio: 0,
        video: 0,
      },
      preTime_: {
        audio: 0,
        video: 0,
      },
      curTime_: {
        audio: 0,
        video: 0,
      },
      curDataLen_: {
        audio: 0,
        video: 0,
      },
      preDataLen_: {
        audio: 0,
        video: 0,
      },
    };
    this.rateHelper_ = {
      rateVal_: {
        audio: [],
        video: [],
      },
      rateArrLenLimit_: 10,
      preComputedRateTime_: {
        audio: 0,
        video: 0,
      },
      curTime: {
        audio: 0,
        video: 0,
      },
      preTime: {
        audio: 0,
        video: 0,
      },
      curDataLen: {
        audio: 0,
        video: 0,
      },
      preDataLen: {
        audio: 0,
        video: 0,
      },
    };
  }

  destroy() {
    super.destroy();
    this.config_ = null;
    this.playUri_ = null;
    this.flvInfo_ = null;
    this.callbackMap_ = null;
    this.unWatchFunc_ = null;
    this.parsedDataLength_ = null;
    this.parsedData_ = null;
    this.stats_ = null;
    this.rateHelper_ = null;
  }

  loadFlvPlayer() {
    if (flvjs.isSupported()) {
      flvjs.LoggingControl.enableVerbose = true;
      flvjs.LoggingControl.forceGlobalTag = true;

      this.flvInfo_.remotePlayer = flvjs.createPlayer(this.config_);
      this.firstIframeHelper_.connectTime = new Date().getTime();

      let video_element = this.vElement_;

      this.flvInfo_.remotePlayer.attachMediaElement(video_element);

      this.addFlvVideoListener();

      let cb = this.callbackMap_ != null ? this.callbackMap_['check_element_state_cb'] : null;
      this.startCheckVideoElementState('checkElementState', cb, 1000);

      console.log('config log', flvjs.LoggingControl.getConfig());
    } else {
      this.callCallback('error_cb', 'Browser Error', '', { code: -1, msg: 'Flv Not Supported' });
      console.log('flv not supported');
    }
  }

  addFlvVideoListener() {
    let flvplayer = this.flvInfo_.remotePlayer;

    flvplayer.on('media_info', (res) => {
      // console.log('media_info', res)
      this.callCallback('media_info_cb', res);
    });

    // flvplayer.on('media_segment', (type, data) => {
    //   console.log('type', type, 'media_segment', data)
    // });

    // flvplayer.on('scriptdata_arrived', (res) => {
    //   console.log('scriptdata_arrived', res)
    // });
    flvplayer.on('metadata_arrived', (res) => {
      // console.log('metadata_arrived', res)
      this.callCallback('metadata_arrived_cb', res);
    });
    // 提供播放统计信息，例如丢帧，当前速度等。
    flvplayer.on('statistics_info', (res) => {
      /**
       currentSegmentIndex: 0
       decodedFrames: 0
       droppedFrames: 0
       hasRedirect: true
       loaderType: "fetch-stream-loader"
       playerType: "FlvPlayer"
       redirectedURL: "https://de7c08e004ffa0209dc8138a3a336257.v.smtcdns.net/5000.liveplay.myqcloud.com/live/test_1621596383.flv?txSecret=7f5128b855308f2e00fd91f9c39a2b76&txTime=60A7D8FF&svr_type=live_oc&tencent_test_client_ip=183.62.151.18&dispatch_from=ztc100.115.172.196&utime=1621946217109_0"
       speed: 611.180259464667   网速，单位是KB/s
       totalSegmentCount: 1
       url: "https://5000.liveplay.myqcloud.com/live/test_1621596383.flv?txSecret=7f5128b855308f2e00fd91f9c39a2b76&txTime=60A7D8FF"
       */
      // console.log('statistics_info', res);
      this.callCallback('statistics_info_cb', res);
    });
    flvplayer.on('error', (res, media_err, info) => {
      console.log('error_cb', res, media_err, info);
      this.callCallback('error_cb', res, media_err, info);
    });
    flvplayer.on('loading_complete', (res) => {
      console.log('loading_complete_cb', res);
      this.callCallback('loading_complete_cb', res);
    });

    // flvplayer.on('av_data_arrived', (type, data) => {
    //   console.log('av_data_arrived', type, data)
    //   let cb = this.callbackMap_ != null ? this.callbackMap_['av_data_arrived'] : null;
    //   if (cb) {
    //     cb(type, data);
    //   }
    // });

    flvplayer.on('flv_header_arrived', (header) => {
      console.log('header: ', header);
      this.callCallback('flv_header_arrived_cb', header);
      this.getCdnBuffer(1000);
      let nowTimestamp = new Date().getTime();
      this.rateHelper_.preComputedRateTime_ = {
        audio: nowTimestamp,
        video: nowTimestamp,
      };

      let cb = this.callbackMap_ != null ? this.callbackMap_['flv_rate_info_cb'] : null;
      this.startInterval('avRateTrigger', () => this.avcAvRateComputed(cb), 1000);

      let cb2 = this.callbackMap_ != null ? this.callbackMap_['fps_info_cb'] : null;
      this.startInterval('fpsTrigger', () => this.fpsCB(cb2), 1000);
    });

    flvplayer.on('flv_tag_parsed', (type, data) => {
      let isAudio = type === 'aac_audio';
      let isVideo = type === 'h264_video';
      let timestamp = new Date().getTime();

      if (this.isAdd_ && (isAudio || isVideo)) {
        this.parsedDataLength_ += data.length;
        if (type === 'h264_video') {
          this.parsedData_.push(data);
          // 获取首个I帧耗时
          if (data.isKeyframe && this.firstIframeHelper_.isFirstIframe) {
            this.firstIframeHelper_.isFirstIframe = false;
            if (this.firstIframeHelper_.connectTime > 0) {
              this.callCallback('first_Iframe_time_cb', {
                time: timestamp - this.firstIframeHelper_.connectTime,
              });
            }
          }
        }
      }

      // fps计算相关
      if (isAudio) {
        this.fpsHelper_.curTime_.audio = data.pts;
        this.fpsHelper_.curDataLen_.audio++;
        // console.log(data.pts)
      } else if (isVideo) {
        this.fpsHelper_.curTime_.video = data.pts;
        // for (let j = 0; j < data.units.length; j++) {
        //   if (data.units[j].type >= 1 && data.units[j].type <= 5) {
        this.fpsHelper_.curDataLen_.video++;
        // }
        // }
        // console.log(data)
        if (data.isKeyframe) {
          // 计算fps
          this.fpsComputed('video');
        }
      }
      // 计算音频和视频码率
      if (isAudio || isVideo) {
        let type = null;
        if (isAudio) {
          type = 'audio';
        } else if (isVideo) {
          type = 'video';
        }
        if (type) {
          this.rateHelper_.curDataLen[type] += data.length;
          this.rateHelper_.curTime[type] = new Date().getTime();

          // if (isVideo && data.isKeyframe) {
          //   let cb = this.callbackMap_ != null ? this.callbackMap_['flv_rate_info_cb'] : null;
          //   setTimeout(this.avcAvRateComputed.bind(this), 1, cb)
          // }
        }
      }
      // 计算sps及pps，暂不需要
      // if (type === 'avc_decoder_configuration_record') {
      //   setTimeout(() => {
      //     const ctype = type;
      //     const cdata = data;
      //     let sps = cdata.sps;
      //     let spsArr = [];
      //     let pps = cdata.pps;
      //     let ppsArr = [];
      //     for (let i = 0; i < sps.spsCount; i++) {
      //       spsArr.push(avUtil.parseSPS(sps.sps[i]));
      //     }
      //     cdata.sps.sps = spsArr;
      //     for (let i = 0; i < pps.ppsCount; i++) {
      //       ppsArr.push(avUtil.parsePPS(pps.pps[i]));
      //     }
      //     cdata.pps.pps = ppsArr;
      //     this.callCallback('flv_tag_parsed_cb', ctype, cdata);
      //   }, 1);
      // } else {
      //   this.callCallback('flv_tag_parsed_cb', type, data);
      // }
      if (type === 'h264_video' && data.isKeyframe) {
        setTimeout(() => {
          this.preIframePts = this.currentFramePts;
          this.currentFramePts = data.pts;
          if (this.preIframePts > 0) {
            // http://5000-test3.liveplay.myqcloud.com/live/test_hongkong1.flv
            // 会产生负数，回绕问题
            const gop = ((this.currentFramePts - this.preIframePts) / 1000).toFixed(2);
            if (gop >= 0) {
              this.callCallback('gop_arrived', gop);
            }
          }
        }, 10);
      }
    });
  }

  // 获取cdn buffer
  getCdnBuffer(time) {
    setTimeout(() => {
      let i = 0;
      let length = this.parsedData_.length;
      for (; i < this.parsedData_.length; i++) {
        if (this.parsedData_[i].isKeyframe) {
          break;
        }
      }
      if (this.parsedData_[length - 1] !== undefined) {
        let deltaPts = this.parsedData_[length - 1].pts - this.parsedData_[i].pts;
        this.callCallback('start_fetch_after_minus_buffer_cb', this.parsedDataLength_, deltaPts);
      }
    }, time);
  }

  fpsCB(cb) {
    this.fpsComputed('audio');
    if (cb) {
      cb(this.fpsHelper_.fpsVal_);
    }
  }

  fpsComputed(av_type) {
    let type = av_type;
    if (this.fpsHelper_.preDataLen_[type] > 0) {
      let detla = (this.fpsHelper_.curTime_[type] - this.fpsHelper_.preTime_[type]) / 1000.0;
      if (detla <= 0) {
        return;
      }
      let fps = (this.fpsHelper_.curDataLen_[type] - this.fpsHelper_.preDataLen_[type]) / detla;
      this.fpsHelper_.fpsVal_[type] = fps;
    }
    this.fpsHelper_.preTime_[type] = this.fpsHelper_.curTime_[type];
    this.fpsHelper_.preDataLen_[type] = this.fpsHelper_.curDataLen_[type];
  }

  // 默认10个音频或者视频的速率的平均值
  avcAvRateComputed(cb) {
    let types = ['audio', 'video'];
    for (let i = 0; i < types.length; i++) {
      let type = types[i];
      if (this.rateHelper_.preTime[type] > 0) {
        let len = this.rateHelper_.curDataLen[type] - this.rateHelper_.preDataLen[type];
        // let detla = nowTimestamp - this.rateHelper_.preComputedRateTime_[type];
        let detla = this.rateHelper_.curTime[type] - this.rateHelper_.preTime[type];
        // let arr = this.rateHelper_.rateVal_[type];
        // console.log('detla', detla, this.rateHelper_.curTime[type], this.rateHelper_.preTime[type])
        if (detla <= 0) {
          continue;
        }

        let rate = (len * 8) / (detla / 1000.0);
        // this._fixArr(arr, this.rateHelper_.rateArrLenLimit_);
        this.rateHelper_.rateVal_[type] = rate;
      }
      this.rateHelper_.preDataLen[type] = this.rateHelper_.curDataLen[type];
      this.rateHelper_.preTime[type] = this.rateHelper_.curTime[type];
    }
    let audioRate = this.rateHelper_.rateVal_['audio'];
    let videoRate = this.rateHelper_.rateVal_['video'];
    if (cb) {
      cb({ audioRate: audioRate, videoRate: videoRate });
    }
  }

  // flv 播放
  play() {
    let player = this.flvInfo_.remotePlayer;
    if (player) {
      player.load();
      player.play();
    }
  }

  // flv停止
  stop() {
    if (this.flvInfo_.remotePlayer) {
      this.flvInfo_.remotePlayer.destroy();
      this.flvInfo_.remotePlayer = null;
    }
  }

  // 重置数据
  initData() {
    this.firstIframeHelper_ = {
      connectTime: 0,
      isFirstIframe: true,
    };
    this.flvInfo_ = {
      remotePlayer: null,
    };
    this.unWatchFunc_ = {};
    this.parsedDataLength_ = 0;
    this.parsedData_ = [];
    this.isAdd_ = true;
    this.stats_ = {
      audioRate: 0,
      videoRate: 0,
    };
    this.fpsHelper_ = {
      fpsVal_: {
        audio: 0,
        video: 0,
      },
      preTime_: {
        audio: 0,
        video: 0,
      },
      curTime_: {
        audio: 0,
        video: 0,
      },
      curDataLen_: {
        audio: 0,
        video: 0,
      },
      preDataLen_: {
        audio: 0,
        video: 0,
      },
    };
    this.rateHelper_ = {
      rateVal_: {
        audio: 0,
        video: 0,
      },
      rateArrLenLimit_: 10,
      preComputedRateTime_: {
        audio: 0,
        video: 0,
      },
      curDataLen: {
        audio: 0,
        video: 0,
      },
      preDataLen: {
        audio: 0,
        video: 0,
      },
    };
  }

  reset() {
    this.stop();
    this.stopCheckVideoElementState('checkElementState');
    this.stopInterval('avRateTrigger');
    this.stopInterval('fpsTrigger');
    this.initData();
  }
}
