// @ts-nocheck

import hlsjs from '../static/hls';
import BaseVideoHandler from './basehandler';
export default class HlsHandler extends BaseVideoHandler {
  hlsInfo_;
  config_;
  playUri_;
  callbackMap_;

  stats_;

  firstTSTimeHelper_;
  fpsHelper_;

  rateInfo_;

  constructor(playUri, hls_config, velement, callbackMap) {
    super(velement);
    this.config_ = hls_config;
    this.playUri_ = playUri;
    this.hlsInfo_ = {
      remoteHlsPlayer: null,
    };
    this.callbackMap_ = callbackMap;
    this.rateInfo_ = {
      audioRate: 0,
      videoRate: 0,
    };
    this.stats_ = {
      autoLevelAvg: -1,
      autoLevelCappingLast: -1,
      autoLevelLast: -1, // 最后选择的level
      autoLevelSwitch: -1, // 当前自动选择的level
      fragAvgKbps: 0, // frag的平均码率
      fragAvgLatency: 0, // frag的延时
      fragAvgProcess: 0, // frag的处理耗时
      fragBuffered: 0,
      fragBufferedBytes: 0,
      fragChangedAuto: 0,
      fragChangedManual: 0,
      fragLastKbps: 0,
      fragLastProcess: 0,
      fragProgramDateTime: 0,
      fragStart: 0,
      fraglastLatency: 0,
      fragparsingKbps: 0,
      fragparsingMs: 0,
      levelNumber: 0,
      levelParsed: 3,
      levelParsingUs: 0,
      levelStart: -1,
      manualLevelLast: 0,
      manualLevelSwitch: 0,

      sumLatency: 0,
      sumKbps: 0,
      sumProcess: 0,
      sumParsing: 0,

      sumLevelParsingMs: 0,

      // 下载速率
      audioLastKbps: 0,
      videoLastKbps: 0,

      // 码率
      audioRatebps: 0,
      videoRatebps: 0,

      tagList: {},
    };
    this.firstTSTimeHelper_ = {
      connectTime: 0,
      firstLoadedM3U8: true,
      isFirstTS: true,
    };
    this.fpsHelper_ = {
      prePts_: {
        audio: 0,
        video: 0,
      },
      curPts_: {
        audio: 0,
        video: 0,
      },
      curDataLen_: {
        audio: 0,
        video: 0,
      },
      preDataLen_: {
        audio: 0,
        video: 0,
      },
    };
  }

  destroy() {
    super.destroy();
    this.hlsInfo_ = null;
    this.config_ = null;
    this.playUri_ = null;
    this.callbackMap_ = null;
    this.stats_ = null;
  }

  loadHlsPlayer() {
    return new Promise((resolve, reject) => {
      if (hlsjs.isSupported()) {
        // this.hlsInfo_.remoteHlsPlayer = new hlsjs({
        //   debug: false,
        //   enableWorker: true,
        //   // lowLatencyMode: true
        // });
        this.hlsInfo_.remoteHlsPlayer = new hlsjs(this.config_);

        this.hlsInfo_.remoteHlsPlayer.attachMedia(this.vElement_);

        resolve();
      } else {
        this.callCallback('error_cb', 'Browser Error', '', { code: -1, msg: 'Hls Not Supported' });
        console.log('hls not supported');
        reject();
      }
    });
  }

  addHlsVideoListener() {
    let hls = this.hlsInfo_.remoteHlsPlayer;
    let velement = this.vElement_;
    let playUri = this.playUri_;
    hls.on(hlsjs.Events.MEDIA_ATTACHED, () => {
      console.log('video and hls.js are now bound together !');
      hls.loadSource(playUri);
    });

    hls.on(hlsjs.Events.MANIFEST_PARSED, (event, data) => {
      let cb = this.callbackMap_ != null ? this.callbackMap_['check_element_state_cb'] : null;
      // 需要开启
      this.startCheckVideoElementState('checkElementState', cb, 1000);
      this.play();

      let cb3 = this.callbackMap_ != null ? this.callbackMap_['hls_rate_info_cb'] : null;
      this.startInterval('avRateTrigger', () => this.avcAvRateComputed(cb3), 1000);

      console.log('manifest', data);

      this.stats_.levelNumber = data.levels.length;
      this.stats_.levelParsed = 0;
    });

    hls.on(hlsjs.Events.BUFFER_CODECS, (eventName, data) => {
      console.log('eventName: BUFFER_CODECS ', eventName, ' data: ', data);
      let metadata = {};
      if (data.audio) {
        metadata['audioCodec'] = data.audio.codec;
        metadata['audioContainer'] = data.audio.container;
        metadata['channelCount'] = data.audio.channelCount | data.audio.metadata.channelCount;
      }
      if (data.video) {
        metadata['width'] = data.video.width | data.video.metadata.width;
        metadata['height'] = data.video.height | data.video.metadata.height;
        metadata['videoCodec'] = data.video.codec;
        metadata['videoContainer'] = data.video.container;
      }
      this.callCallback('hls_metadata_cb', metadata);
    });

    hls.on(hlsjs.Events.LEVEL_LOADED, (eventName, data) => {
      console.log('eventName: LEVEL_LOADED ', eventName, ' data: ', data, data.details.url);
      if (this.firstTSTimeHelper_.firstLoadedM3U8) {
        this.firstTSTimeHelper_.firstLoadedM3U8 = false;
        this.firstTSTimeHelper_.connectTime = data.stats.loading.start;
      }
      // const parsingDuration = data.stats.parsing.end - data.stats.loading.end;
      // if (this.stats_.levelParsed) {
      //   this.stats_.sumLevelParsingMs += parsingDuration;
      // } else {
      //   this.stats_.sumLevelParsingMs = parsingDuration;
      // }
      this.stats_.levelParsed++;
      this.stats_.levelParsingUs = Math.round(
        (1000 * this.stats_.sumLevelParsingMs) / this.stats_.levelParsed,
      );

      // 探测m3u8
      this.callCallback('hls_level_loaded_cb', {
        loadTime: data.stats.loading.end - data.stats.loading.start,
        parseTime: data.stats.parsing.end - data.stats.parsing.start,
        length: data.stats.total,
        fragments: data.details.fragments,
        url: data.details.url,
      });
    });

    hls.on(hlsjs.Events.BUFFER_APPENDING, (eventName, data) => {
      let ratebps = (8 * data.data.length) / data.frag.duration;
      if (data.type === 'audio') {
        this.rateInfo_.audioRate = ratebps;
      } else {
        this.rateInfo_.videoRate = ratebps;
      }
    });

    hls.on(hlsjs.Events.BUFFER_APPENDED, (eventName, data) => {
      console.log('eventName: ', eventName, ' data: ', data);
    });
    hls.on(hlsjs.Events.BUFFER_CREATED, (eventName, tracks) => {
      console.log('eventName: ', eventName, ' tracks: ', tracks);
    });
    // hls.on(hlsjs.Events.BUFFER_RESET, (eventName) => {
    //   console.log('BUFFER_RESET');
    // });
    // hls.on(hlsjs.Events.BUFFER_EOS, (eventName) => {
    //   console.log('BUFFER_EOS');
    // })
    //
    hls.on(hlsjs.Events.FRAG_PARSING_METADATA, (eventName, data) => {
      console.log('eventName: ', eventName, 'Id3 samples ', data);
    });
    //
    // hls.on(hlsjs.Events.FRAG_PARSING_INIT_SEGMENT, function (eventName, data) {
    //   console.log('eventName: ', eventName, " data: ", data);
    // });
    //
    // hls.on(hlsjs.Events.DESTROYING, function () {
    //   console.log('DESTROYING');
    // });
    //
    // hls.on(hlsjs.Events.LEVELS_UPDATED, function (eventName, levels) {
    //   console.log('eventName: ', eventName, " levels: ", levels);
    // });
    // hls.on(hlsjs.Events.LEVEL_SWITCHED, function (eventName, level) {
    //   console.log('eventName: ', eventName, " levels: ", level);
    // });
    // hls.on(hlsjs.Events.LEVEL_LOADING, function () {
    //   // TODO: mutate level datasets
    //   // Update loadLevel
    // });
    // hls.on(hlsjs.Events.LEVEL_UPDATED, function (eventName, details) {
    //   console.log('eventName: ', eventName, " details: ", details);
    // });
    //
    // hls.on(hlsjs.Events.AUDIO_TRACKS_UPDATED, function (eventName, audioTracks) {
    //   console.log('eventName: ', eventName, " audioTracks: ", audioTracks);
    // });
    //
    // hls.on(hlsjs.Events.AUDIO_TRACK_LOADED, function (eventName, data) {
    //   console.log('eventName: ', eventName, " audioTracks: ", audioTracks);
    // });
    //
    // hls.on(hlsjs.Events.SUBTITLE_TRACKS_UPDATED, function (eventName, subtitleTracks) {
    //   console.log('eventName: ', eventName, " subtitleTracks: ", subtitleTracks);
    // });
    //
    // hls.on(hlsjs.Events.AUDIO_TRACK_SWITCHED, (eventName) => {
    //   // TODO: mutate level datasets
    //   console.log('eventName: ', eventName);
    // });
    // hls.on(hlsjs.Events.SUBTITLE_TRACK_SWITCH, (eventName) => {
    //   // TODO: mutate level datasets
    //   console.log('eventName: ', eventName);
    // });
    // hls.on(hlsjs.Events.FPS_DROP, function (eventName, data) {
    //   console.log('eventName: ', eventName, " data: ", data);
    // });
    hls.on(hlsjs.Events.FRAG_BUFFERED, (eventName, data) => {
      // ************************
      // 特别关注
      // ************************
      console.log('eventName: FRAG_BUFFERED', eventName, ' data: ', data);

      let latency = data.stats.loading.first - data.stats.loading.start;
      let parsing = data.stats.parsing.end - data.stats.loading.end;
      let process = data.stats.buffering.end - data.stats.loading.start;
      let bitrate =
        (8 * data.stats.total) / (data.stats.buffering.end - data.stats.loading.first) / 1024; // kbps
      if (this.stats_.fragBuffered) {
        this.stats_.fragBuffered++;
      } else {
        this.stats_.fragBuffered = 1;
        this.stats_.fragBufferedBytes = 0;
        this.stats_.sumLatency = 0;
        this.stats_.sumKbps = 0;
        this.stats_.sumProcess = 0;
        this.stats_.sumParsing = 0;
      }
      this.stats_.fraglastLatency = latency;
      this.stats_.sumLatency += latency;
      this.stats_.fragAvgLatency = Math.round(this.stats_.sumLatency / this.stats_.fragBuffered);
      this.stats_.fragLastProcess = process;
      this.stats_.sumProcess += process;
      this.stats_.sumParsing += parsing;
      this.stats_.fragAvgProcess = Math.round(this.stats_.sumProcess / this.stats_.fragBuffered);
      this.stats_.fragLastKbps = bitrate;
      this.stats_.sumKbps += bitrate;
      this.stats_.fragAvgKbps = Math.round(this.stats_.sumKbps / this.stats_.fragBuffered);
      this.stats_.fragBufferedBytes += data.stats.total;
      this.stats_.fragparsingKbps = Math.round(
        (8 * this.stats_.fragBufferedBytes) / this.stats_.sumParsing,
      );
      this.stats_.fragparsingMs = Math.round(this.stats_.sumParsing);
      this.stats_.autoLevelCappingLast = hls.autoLevelCapping;

      if (this.firstTSTimeHelper_.isFirstTS) {
        this.firstTSTimeHelper_.isFirstTS = false;
        this.callCallback('first_TS_time_cb', {
          time: (data.stats.loading.end - this.firstTSTimeHelper_.connectTime).toFixed(2),
        });
      }

      let fragCBData = {
        loadTime: data.stats.loading.end - data.stats.loading.start,
        parseTime: data.stats.parsing.end - data.stats.loading.end,
        fragUrl: data.frag.baseurl + data.frag.relurl,
        duration: data.frag.duration,
        level: data.frag.level,
      };
      this.callCallback('frag_buffered_cb', fragCBData);
    });

    hls.on(hlsjs.Events.FRAG_CHANGED, (eventName, data) => {
      console.log('eventName: FRAG_CHANGED', eventName, ' data: ', data);
      this.stats_.tagList = data.frag.tagList;
      const level = data.frag.level;
      const autoLevel = hls.autoLevelEnabled;
      if (this.stats_.levelStart < 0) {
        this.stats_.levelStart = level;
      }

      this.stats_.fragProgramDateTime = data.frag.programDateTime;
      this.stats_.fragStart = data.frag.start;

      if (autoLevel) {
        if (this.stats_.fragChangedAuto) {
          this.stats_.fragChangedAuto++;
          if (this.stats_.levelLastAuto && level !== this.stats_.autoLevelLast) {
            this.stats_.autoLevelSwitch++;
          }
        } else {
          this.stats_.autoLevelSwitch = 0;
          this.stats_.fragChangedAuto = 1;
        }
        this.stats_.autoLevelLast = level;
      } else {
        if (this.stats_.fragChangedManual) {
          this.stats_.fragChangedManual++;
          if (!this.stats_.levelLastAuto && level !== this.stats_.manualLevelLast) {
            this.stats_.manualLevelSwitch++;
          }
        } else {
          this.stats_.manualLevelSwitch = 0;
          this.stats_.fragChangedManual = 1;
        }
        this.stats_.manualLevelLast = level;
      }
      this.stats_.levelLastAuto = autoLevel;
    });

    // hls.on(hlsjs.Events.FRAG_PARSED, function (event, data) {
    //   console.log('FRAG_PARSED', event, data);
    // });

    hls.on(hlsjs.Events.ERROR, (event, data) => {
      let errorType = data.type;
      let errorDetails = data.details;
      let errorFatal = data.fatal;
      console.error('error', data);
      // if (errorFatal) {
      switch (errorType) {
        case hlsjs.ErrorTypes.NETWORK_ERROR:
          // try to recover network error
          // console.log('fatal network error encountered, try to recover');
          // type, media_err, info
          if (data.response) {
            this.callCallback('error_cb', errorType, errorFatal, data.response);
          } else {
            this.callCallback('error_cb', errorType, errorFatal, { code: -1, text: errorDetails });
          }
          // hls.startLoad();
          return;
        case hlsjs.ErrorTypes.MEDIA_ERROR:
          hls.recoverMediaError();
          break;
        case hlsjs.ErrorTypes.KEY_SYSTEM_ERROR:
        case hlsjs.ErrorTypes.MUX_ERROR:
        default:
          // cannot recover
          this.reset();
          break;
      }

      switch (errorDetails) {
        case hlsjs.ErrorDetails.MANIFEST_LOAD_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, data.response);
          return;
        }
        case hlsjs.ErrorDetails.MANIFEST_LOAD_TIMEOUT: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Timeout while loading manifest',
          });
          return;
        }
        case hlsjs.ErrorDetails.MANIFEST_PARSING_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Error while parsing manifest:' + data.reason,
          });
          return;
        }
        case hlsjs.ErrorDetails.LEVEL_EMPTY_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text:
              'Error while parsing manifest:' +
              'Loaded level contains no fragments ' +
              data.level +
              ' ' +
              data.url,
          });
          return;
        }
        case hlsjs.ErrorDetails.LEVEL_LOAD_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Error while loading level playlist ' + data.context.level + ' ' + data.url,
          });
          return;
        }
        case hlsjs.ErrorDetails.LEVEL_LOAD_TIMEOUT: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Timeout while loading level playlist ' + data.context.level + ' ' + data.url,
          });
          return;
        }
        case hlsjs.ErrorDetails.LEVEL_SWITCH_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Error while trying to switch to level ' + data.level,
          });
          return;
        }
        case hlsjs.ErrorDetails.FRAG_LOAD_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Error while loading fragment ' + data.frag.url,
          });
          return;
        }
        case hlsjs.ErrorDetails.FRAG_LOAD_TIMEOUT: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Timeout while loading fragment ' + data.frag.url,
          });
          return;
        }
        case hlsjs.ErrorDetails.FRAG_LOOP_LOADING_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Fragment-loop loading error',
          });
          return;
        }
        case hlsjs.ErrorDetails.FRAG_DECRYPT_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Decrypting error:' + data.reason,
          });
          return;
        }
        case hlsjs.ErrorDetails.FRAG_PARSING_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Parsing error:' + data.reason,
          });
          return;
        }
        case hlsjs.ErrorDetails.KEY_LOAD_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Error while loading key ' + data.frag.decryptdata.uri,
          });
          return;
        }
        case hlsjs.ErrorDetails.KEY_LOAD_TIMEOUT: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Timeout while loading key ' + data.frag.decryptdata.uri,
          });
          return;
        }
        case hlsjs.ErrorDetails.BUFFER_APPEND_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Buffer append error',
          });
          return;
        }
        case hlsjs.ErrorDetails.BUFFER_ADD_CODEC_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Buffer add codec error for ' + data.mimeType + ':' + data.error.message,
          });
          return;
        }
        case hlsjs.ErrorDetails.BUFFER_APPENDING_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Buffer appending error',
          });
          return;
        }
        case hlsjs.ErrorDetails.BUFFER_STALLED_ERROR: {
          this.callCallback('error_cb', errorType, errorFatal, {
            code: -1,
            text: 'Buffer stalled error',
          });
          return;
        }
        default:
          break;
      }

      if (data.fatal) {
        switch (data.type) {
          case hlsjs.ErrorTypes.MEDIA_ERROR: {
            this.callCallback('error_cb', errorType, errorFatal, {
              code: -1,
              text: `A media error occurred: ${data.details}`,
            });
            return;
          }
          case hlsjs.ErrorTypes.NETWORK_ERROR: {
            this.callCallback('error_cb', errorType, errorFatal, {
              code: -1,
              text: `A network error occurred: ${data.details}`,
            });
            return;
          }
          default: {
            this.callCallback('error_cb', errorType, errorFatal, {
              code: -1,
              text: `An unrecoverable error occurred: ${data.details}`,
            });
            return;
          }
        }
      }
      // }
    });

    hls.on('minlpli_require_demux', (event, data) => {
      let avcData = data.data.avcTrack;
      let audioData = data.data.audioTrack;
      let fpsCbData = {
        audio: 0,
        video: 0,
      };
      // 计算fps
      if (audioData.samples.length > 0) {
        let audioTimeDetla =
          (audioData.samples[audioData.samples.length - 1].pts - audioData.samples[0].pts) /
          audioData.inputTimeScale;
        fpsCbData.audio = audioData.samples.length / audioTimeDetla;
      }
      if (avcData.samples.length > 0) {
        let avcTimeDetla =
          (avcData.samples[avcData.samples.length - 1].pts - avcData.samples[0].pts) /
          avcData.inputTimeScale;
        fpsCbData.video = avcData.samples.length / avcTimeDetla;
      }
      // 计算fps
      this.callCallback('fps_info_cb', fpsCbData);

      // 暂不需要，直接计算 gop
      // this.callCallback('hls_frag_parsed_cb', {
      //   videoTrack: {
      //     type: 'video',
      //     data: avcData.samples,
      //     timescale: avcData.inputTimeScale,
      //   },
      //   audioTrack: {
      //     type: 'audio',
      //     data: audioData.samples,
      //     samplerate: audioData.samplerate,
      //     channelCount: audioData.channelCount,
      //     timescale: audioData.inputTimeScale,
      //     isAAC: audioData.isAAC,
      //   },
      // });

      // 计算 gop
      for (const item of avcData.samples) {
        if (item.key) {
          setTimeout(() => {
            this.preIframePts = this.currentFramePts;
            this.currentFramePts = item.pts;
            if (this.preIframePts > 0) {
              // http://5000-test3.liveplay.myqcloud.com/live/test_hongkong1.flv
              // 会产生负数，回绕问题
              const gop = (
                (this.currentFramePts - this.preIframePts) /
                avcData.inputTimeScale
              ).toFixed(2);
              if (gop >= 0) {
                this.callCallback('gop_arrived', gop);
              }
            }
          }, 10);
        }
      }
    });
  }

  // fps 有点问题 ****
  // fpsComputed(cb) {
  //
  //   let types = ['audio', 'video'];
  //   let res = {};
  //   for (let i = 0; i < types.length; i++) {
  //     let type = types[i];
  //     if (this.fpsHelper_.preDataLen_[type] > 0) {
  //       let detla = (this.fpsHelper_.curPts_[type] - this.fpsHelper_.prePts_[type]) / 1000.0;
  //       if (detla <= 0) {
  //         return;
  //       }
  //       let fps = (this.fpsHelper_.curDataLen_[type] - this.fpsHelper_.preDataLen_[type]) / detla;
  //       res[type] = fps;
  //     }
  //     this.fpsHelper_.prePts_[type] = this.fpsHelper_.curPts_[type];
  //     this.fpsHelper_.preDataLen_[type] = this.fpsHelper_.curDataLen_[type];
  //   }
  //   let arr = Object.keys(res);
  //   if (cb && arr.length > 1) {
  //     cb(res)
  //   }
  //
  // }

  // 默认10个音频或者视频的速率的平均值
  avcAvRateComputed(cb) {
    if (cb) {
      cb(this.rateInfo_);
    }
  }

  play() {
    // this.startInterval('test', function () {
    //   // console.log('this.hlsInfo_.remoteHlsPlayer',this.hlsInfo_.remoteHlsPlayer);
    //   console.log('this.hlsInfo_.remoteHlsPlayer', this.hlsInfo_.remoteHlsPlayer.streamController.transmuxer);
    //   // console.log('this.hlsInfo_.remoteHlsPlayer', this.hlsInfo_.remoteHlsPlayer.streamController.transmuxer ? this.hlsInfo_.remoteHlsPlayer.streamController.transmuxer.worker:'null');
    // }, null, 100)
    this.vElement_.play();
  }

  stop() {
    if (this.hlsInfo_ && this.hlsInfo_.remoteHlsPlayer) {
      this.vElement_.pause();
      this.hlsInfo_.remoteHlsPlayer.destroy();
    }
  }

  resetData() {
    this.hlsInfo_ = {
      remoteHlsPlayer: null,
    };
    this.firstTSTimeHelper_ = {
      connectTime: 0,
      firstLoadedM3U8: true,
      isFirstTS: true,
    };
  }

  reset() {
    this.stop();
    this.stopCheckVideoElementState('checkElementState');
    this.stopInterval('avRateTrigger');
    this.resetData();
  }
}
