// @ts-nocheck
import dashjs from '../static/dash.all.debug';
import thumbcoiljs from '../static/thumbcoil';
import BaseVideoHandler from './basehandler';

export default class DashHandler extends BaseVideoHandler {
  dashInfo_;
  config_;
  playUri_;
  callbackMap_;

  firstM4STimeHelper_;

  // mp4boxfile_;
  filepos_;
  length;

  constructor(playUri, config, velement, callbackMap) {
    super(velement);
    this.config_ = config;
    this.playUri_ = playUri;
    this.dashInfo_ = {
      remotePlayer: null,
    };
    this.callbackMap_ = callbackMap;

    this.rateInfo_ = {};

    this.firstM4STimeHelper_ = {
      connectTime: 0,
      firstLoadedMPD: true,
      isFirstM4S: true,
    };

    this.fpsHelper_ = {};

    // this.mp4boxfile_ = mp4boxjs.createFile();
    // this.filepos_ = 0;
    // mp4boxjs.Log.setLogLevel(mp4boxjs.Log.d);
  }

  destroy() {
    super.destroy();
    this.dashInfo_ = null;
    this.config_ = null;
    this.playUri_ = null;
    this.callbackMap_ = null;
    // this.mp4boxfile_ = null;
  }

  loadDashPlayer() {
    return new Promise((resolve, reject) => {
      this.dashInfo_.remotePlayer = dashjs.MediaPlayer().create();
      this.dashInfo_.remotePlayer.initialize(this.vElement_, this.playUri_, false);
      this.dashInfo_.remotePlayer.updateSettings(this.config_);
      // 添加dash.js监听
      this.addDashVideoListener();
      // 添加MP4的监听
      // this.addMp4Listener();
      resolve();
    });
  }

  addDashVideoListener() {
    let player = this.dashInfo_.remotePlayer;
    // manifest
    player.on(dashjs.MediaPlayer.events.MANIFEST_LOADED, (e) => {
      // 记录首次加载mdp的时间
      // 每隔一段时间get mdp文件
      console.log('e: ', e, e.data.loadedTime.getTime(), e.data.publishTime.getTime());
      // 探测mdp，暂时有问题
      this.callCallback('dash_mpd_loaded_cb', {
        loadTime: e.data.requestEndDate.getTime() - e.data.requestStartDate.getTime(),
        url: e.data.url,
      });
    });
    player.on(dashjs.MediaPlayer.events.MANIFEST_VALIDITY_CHANGED, (e) => {
      console.log('e: ', e);
    });
    player.on(dashjs.MediaPlayer.events.METRIC_UPDATED, (e) => {
      if (this.firstM4STimeHelper_.firstLoadedMPD) {
        this.firstM4STimeHelper_.firstLoadedMPD = false;
        this.firstM4STimeHelper_.connectTime = e.value.requestTime;
      }
      // console.log('e: ', e, e.value.fetchTime.getTime(), e.value.requestTime.getTime());
    });

    // meta data
    // player.on(dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED, function (e){
    //   console.log('e: ', e);
    // });

    // player.on(dashjs.MediaPlayer.events.EVENT_MODE_ON_START, function (e) {
    //   console.log('e: ', e);
    // });
    // player.on(dashjs.MediaPlayer.events.BUFFER_LEVEL_STATE_CHANGED, function (e){
    //   console.log('e: ', e);
    // });
    // player.on(dashjs.MediaPlayer.events.BUFFER_LOADED, function (e){
    //   console.log('e: ', e);
    // });

    // player.on(dashjs.MediaPlayer.events.EVENT_MODE_ON_RECEIVE, function (e) {
    //   console.log('e: ', e);
    // });
    // player.on(dashjs.MediaPlayer.events.EVENT_MODE_ON_START, function (e) {
    //   console.log('e: ', e);
    // });
    // player.on(dashjs.MediaPlayer.events.FRAGMENT_LOADING_STARTED, function (e) {
    //   // 可以计算码率
    //   console.log('e: ', e);
    // });
    player.on(dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED, (e) => {
      // 可以计算码率

      // 解析fmp4
      this.thumbcoilParseM4s(e.response, e.mediaType, e.request.type);

      let req = e.request;
      if (e.request.type === 'MediaSegment') {
        // 媒体数据
        // 字节数对不上network中的数？？？
        let ratebps = (8 * req.bytesTotal) / req.duration;
        if (e.mediaType === 'audio') {
          this.rateInfo_['audioRate'] = ratebps;
        } else {
          this.rateInfo_['videoRate'] = ratebps;
        }
      } else if (e.request.type === 'InitializationSegment') {
        // 媒体初始化数据
      }

      // frag 获取时间回调
      let fragCBData = {
        loadTime: req.requestEndDate.getTime() - req.requestStartDate.getTime(),
        parseTime: req.requestEndDate.getTime() - req.firstByteDate.getTime(),
        fragUrl: req.url,
        duration: req.duration,
        quality: req.quality,
        mediaType: req.mediaType,
      };

      fragCBData['duration'] = isNaN(req.duration) ? 0 : req.duration;
      this.callCallback('frag_buffered_cb', fragCBData);

      // 首个M4S加载的时间
      if (this.firstM4STimeHelper_.isFirstM4S) {
        this.firstM4STimeHelper_.isFirstM4S = false;
        this.callCallback('first_M4S_time_cb', {
          time: (
            req.requestEndDate.getTime() - this.firstM4STimeHelper_.connectTime.getTime()
          ).toFixed(2),
        });
      }
    });

    player.on(dashjs.MediaPlayer.events.STREAM_INITIALIZING, (e) => {
      console.log('e: ', e);
      let mediaInfo = e.mediaInfo;
      // codec parsed 回调
      if (mediaInfo.type === 'audio') {
        this.callCallback('codec_parsed_cb', {
          type: mediaInfo.type,
          audioCodec: mediaInfo.codec,
        });
      } else if (mediaInfo.type === 'video') {
        this.callCallback('codec_parsed_cb', {
          type: mediaInfo.type,
          videoCodec: mediaInfo.codec,
        });
      }
      // if (data) {
      //   for (let i = 0; i < mediaInfo.bitrateList.length; i++) {
      //     let bitrate = mediaInfo.bitrateList[i];
      //     let config;
      //     if (mediaInfo.type === 'video') {
      //       config = {
      //         'id': bitrate.id,
      //         'width': bitrate.width,
      //         'height': bitrate.height,
      //         'bandwidth': bitrate.bandwidth,
      //       }
      //     } else if (mediaInfo.type === 'audio') {
      //       config = {
      //         'id': bitrate.id,
      //         'bandwidth': bitrate.bandwidth,
      //       }
      //     }
      //     data.bitrateList.push(config);
      //   }
      //   that.callCallback('dash_media_info_cb', data);
      // }
    });

    // player.on(dashjs.MediaPlayer.events.STREAM_UPDATED, function (e) {
    //   console.log('e: ', e);
    // });
    // player.on(dashjs.MediaPlayer.events.TRACK_CHANGE_RENDERED, function (e) {
    //   console.log('e: ', e);
    // });

    // hlsjs中未加
    // player.on(dashjs.MediaPlayer.events.PLAYBACK_ENDED, (e) => {
    //   console.log('e: ', e);
    // });

    // error
    player.on(dashjs.MediaPlayer.events.ERROR, (e) => {
      this.callCallback('error_cb', e.error.code, e.error.message, e.error.data);
      console.log('e: ', e);
    });
    player.on(dashjs.MediaPlayer.events.PLAYBACK_ERROR, (e) => {
      console.log('e: ', e);
    });
  }

  thumbcoilParseM4s(data, mediaType, fragType) {
    let arr = new Uint8Array(data);
    if (fragType === 'MediaSegment') {
      this.asynthumbcoilParseM4s(arr, mediaType);
    } else if (fragType === 'InitializationSegment') {
      this.asynthumbcoilParseM4sInit(arr, mediaType);
    }
  }

  asynthumbcoilParseM4sInit(data, mediaType) {
    setTimeout(() => {
      let inputParsed = thumbcoiljs['mp4Inspector'].inspect(data);
      for (let i = 0; i < inputParsed.length; i++) {
        let atom = inputParsed[i];
        if (atom.type === 'moov') {
          let config = this.findAbbr(
            atom,
            ['trak', 'mdia', 'minf', 'stbl', 'stsd'],
            0,
            'sampleDescriptions',
          );
          this.callCallback('dash_media_info_cb', {
            type: mediaType,
            config: config,
          });
        }
      }
    }, 1);
  }

  asynthumbcoilParseM4s(data, mediaType) {
    function skipFillNal(nalus, j) {
      let rbspType = 'slice_layer_without_partitioning_rbsp';
      let rbspIdrType = 'slice_layer_without_partitioning_rbsp_idr';
      while (!(nalus[j].type === rbspType || nalus[j].type === rbspIdrType)) {
        j++;
      }
      return j;
    }

    setTimeout(() => {
      let inputParsed = thumbcoiljs['mp4Inspector'].inspect(data);
      let timescale = 0;
      let dts = 0;
      let samples = null;
      let nalus = null;
      for (let i = 0; i < inputParsed.length; i++) {
        let atom = inputParsed[i];
        if (atom.type === 'moov') {
          timescale = this.findAbbr(atom, ['trak', 'mdia', 'mdhd'], 0, 'timescale');
        } else if (atom.type === 'moof') {
          // 音频有问题
          dts = this.findAbbr(atom, ['traf', 'tfdt'], 0, 'baseMediaDecodeTime');
          console.log(atom);

          samples = this.findAbbr(atom, ['traf', 'trun'], 0, 'samples');
        } else if (atom.type === 'mdat') {
          nalus = atom.nals;
        }
      }
      let j = 0;
      if (mediaType === 'video') {
        for (const item of samples) {
          j = skipFillNal(nalus, j);
          const nal = nalus[j];
          if (nal.type_num === 5) {
            const pts = dts + item.compositionTimeOffset;
            // eslint-disable-next-line no-loop-func
            setTimeout(() => {
              this.preIframePts = this.currentFramePts;
              this.currentFramePts = pts;
              if (this.preIframePts > 0) {
                const gop = ((this.currentFramePts - this.preIframePts) / timescale).toFixed(2);
                if (gop >= 0) {
                  this.callCallback('gop_arrived', gop);
                }
              }
            }, 10);
          }
        }
      }
      // if (mediaType === 'video') {
      //   this.callCallback('fmp4_parsed_cb', {
      //     type: 'video',
      //     dts: dts,
      //     timescale: timescale,
      //     samples: samples,
      //     nalus: nalus,
      //   });
      // } else {
      //   this.callCallback('fmp4_parsed_cb', {
      //     type: 'audio',
      //     dts: dts,
      //     timescale: timescale,
      //     samples: samples,
      //   });
      // }

      // 计算fps
      // let audioTimeDetla = (audioData.samples[audioData.samples.length - 1].pts - audioData.samples[0].pts) / audioData.inputTimeScale;
      let duration = 0;
      console.log(dts, samples);

      let samplesLen = samples.length;
      for (let i = 0; i < samplesLen; i++) {
        duration += samples[i].duration || samples[i].compositionTimeOffset / 2;
        samples[i].pts = dts + samples[i].compositionTimeOffset;
      }
      console.log((samples[samples.length - 1].pts - samples[0].pts) / timescale);

      this.fpsHelper_[mediaType] = samplesLen / (duration / timescale);
    }, 1);
  }

  findAbbr(dataArr, typeArr, typeArrIdx, abbr) {
    if (typeArr.length === typeArrIdx) {
      return abbr === null ? dataArr : dataArr[abbr];
    }
    for (let i = 0; i < dataArr.boxes.length; i++) {
      let atom = dataArr.boxes[i];
      if (atom.type === typeArr[typeArrIdx]) {
        return this.findAbbr(atom, typeArr, typeArrIdx + 1, abbr);
      }
    }
    return 0;
  }

  avcAvRateComputed(cb) {
    let arr = Object.keys(this.rateInfo_);
    if (cb && arr.length > 1) {
      cb(this.rateInfo_);
    }
  }

  fpsComputed(cb) {
    let fps = this.fpsHelper_;
    let arr = Object.keys(fps);
    if (cb && arr.length > 1) {
      cb(fps);
    }
  }

  play() {
    // this.dashInfo_.remotePlayer.startup();
    this.dashInfo_.remotePlayer.play();

    // video状态回调
    let cb = this.callbackMap_ != null ? this.callbackMap_['check_element_state_cb'] : null;
    this.startCheckVideoElementState('checkElementState', cb, 1000);

    // dash的音频视频速率
    let cb3 = this.callbackMap_ != null ? this.callbackMap_['dash_rate_info_cb'] : null;
    this.startInterval('avRateTrigger', () => this.avcAvRateComputed(cb3), 1000);

    // dash的fps
    let cb4 = this.callbackMap_ != null ? this.callbackMap_['fps_info_cb'] : null;
    this.startInterval('fpsTrigger', () => this.fpsComputed(cb4), 1000);
  }

  stop() {
    if (this.dashInfo_ && this.dashInfo_.remotePlayer) {
      this.dashInfo_.remotePlayer.reset();
      this.dashInfo_.remotePlayer.destroy();
    }
  }

  resetData() {
    this.dashInfo_ = {
      remotePlayer: null,
    };
    this.dashInfo_ = null;
    this.playUri_ = null;
    this.fpsHelper_ = {};
  }

  reset() {
    this.stopCheckVideoElementState('checkElementState');
    this.stopInterval('avRateTrigger');
    this.stopInterval('fpsTrigger');
    this.stop();
    this.resetData();
  }
}
